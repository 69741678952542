import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import FotoLogoOAT from './assets/logoOAT.png';
import FotoLogoOATWhite from './assets/logoOAT_white.png';
import { Loader, useComputedColorScheme  } from '@mantine/core';




const LoaderWrapper = styled.div`
  ${(props) => `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props.dark ? "#242424" : "#fff"};
  z-index: 500;
  `}
`;



const Img = styled.img`

    @media (max-width: 800px) {
        width: 80%;
    }
`;

const Loadder = () => {
  const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

  return (
    <LoaderWrapper dark={computedColorScheme === "dark"}>
      <Img src={computedColorScheme === "dark" ? FotoLogoOATWhite : FotoLogoOAT} />
      <Loader style={{marginTop:"50px"}} color="blue" size="xl" />
    </LoaderWrapper>
  );
};

export default Loadder;
